import { Container } from '@/shared/ui/Container';
import { storeAdmin } from '@/shared/store/admin';
import { useLocale } from 'next-intl';
import { ROUTES } from '@/shared/config/routes';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useScreenSize } from '@/shared/hooks/useScreenSize';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import styled from './NavMenu.module.scss';

export function NavMenu() {
  const locale = useLocale();
  const screenSize = useScreenSize();
  const { currentLocal } = useCurrentLocale();
  function onClickSetIsOpen() {
    storeAdmin.setIsOpenNavMenu();
  }

  function getPathname() {
    if (!storeAdmin.isVerifiedUser) return `/${locale}${ROUTES.auth}`;

    return `/${locale}${ROUTES.search}`;
  }

  return (
    <motion.div
      initial={{ y: '-100%', x: '0%' }}
      transition={{
        type: 'tween',
        ease: 'linear',
        duration: screenSize.width > 819 ? 0.25 : 0,
      }}
      className={styled.main}
    >
      <Container>
        <div className={styled.root}>
          <div className={styled.actions}>
            <Link href={getPathname()} className={styled.login}>
              {currentLocal?.landing.enter}
            </Link>
          </div>

          <div className={styled.navmenu}>
            <div className={styled.menu}>
              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#product"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.about}
                </div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#advantages"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.preimush}
                </div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#systems"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.integration}
                </div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#application"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.areas}
                </div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#functional"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.function}
                </div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#usage"
              >
                <div className={styled.label}>
                  {currentLocal?.landing.burger.used}
                </div>
              </a>
            </div>
            <div onClick={onClickSetIsOpen} className={styled.box} />
          </div>
        </div>
      </Container>
    </motion.div>
  );
}
