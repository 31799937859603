import { ROUTES } from '@/shared/config/routes';
import { useLocale } from 'next-intl';
import { Loader } from '@/shared/ui/Loader';
import { useTransition } from 'react';
import { useRouter } from 'next/navigation';
import { useCurrentLocale } from '@/shared/hooks/useCurrentLocale';
import { userStore } from '@/shared/store/user/userStore';
import styled from './Login.module.scss';

export function Login() {
  const router = useRouter();
  const locale = useLocale();
  const [isPending, startTransition] = useTransition();
  const { currentLocal } = useCurrentLocale();
  function getPathname() {
    if (!userStore.userState.isVerifiedUser) {
      startTransition(() => {
        router.push(`/${locale}${ROUTES.auth}`);
      });
      return;
    }

    startTransition(() => {
      router.push(`/${locale}${ROUTES.search}`);
    });
  }

  return (
    <button type="button" onClick={getPathname} className={styled.container}>
      <div className={styled.button_container} />
      {!isPending ? (
        <div className={styled.button}>{currentLocal?.landing.enter}</div>
      ) : (
        <Loader color="inherit" />
      )}
    </button>
  );
}
